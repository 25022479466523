.hover-button {
  position: relative;
  margin-right:15px;
}

.hover-button:after {
  content: "This dashboard shows last 24 hours processed JobIds and request by default.";
  position: absolute;
  bottom: 100%;
  left: 100%;
  transform: translateX(-20%);
  background-color: black;
  color: #CDFF17;;
  padding: 5px;
  border-radius: 5px;
  display: none;
}

.hover-button:hover::after {
  display: block;
}

.DataTable tr th:last-child {
  text-align: unset !important;
}

.DataTable tr td:last-child{
  text-align: unset !important;
}