.modal{
    position:relative;
    margin:20px 20px 20px 20px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    backdrop-filter: blur(100px);
}

button{
    display: flex;
    justify-content: center;
    background-color:#CDFF17;
    width: 120px;
    color:black;
}


.seen{
    justify-content: space-between;
}

.centered-dialog.fullscreen {
    position: relative;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    max-width: dpx(none);
    border-radius: 0;
  }
  